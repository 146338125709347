<template lang="pug">
  div(class="hero theme-2")

    .corner
      img(src="https://ik.imagekit.io/aryraditya/ceremonyku/corner_2x_sZXnEWtgp.png?tr=w-500")

    .container.py-3
      div.px-3
        h1(class="text-center text-uppercase") Pawiwahan 
          template(v-if="$parent.mepandes.length")
            span(class="d-block d-md-inline-block") &
            |
            | Mepandes
        .name
          .title 
            | {{ $parent.wedding.man.surname }}
            span(class="and" style="margin-top: -.75rem; margin-bottom: -.75rem") &
            | {{ $parent.wedding.woman.surname }}
          
        guest-box(:floating="false" class="mx-auto")
      
    div(class="container-xl text-white")
      swiper(:options="swiperOptions" ref="mySwiper")
        swiper-slide(v-for="image in images" :key="image")
          img(:data-src="image" :key="image" class="img-fluid swiper-lazy")
          .swiper-lazy-preloader.swiper-lazy-preloader-white
        .swiper-button-prev(slot="button-prev")
        .swiper-button-next(slot="button-next")
        //- .swiper-pagination(slot="pagination")
        //- .swiper-scrollbar(slot="scrollbar")
</template>

<script>

import { swiper, swiperSlide } from 'vue-awesome-swiper'
import GuestBox from '@/components/GuestBox'

export default {
  components: {
    swiper,
    swiperSlide,
    GuestBox
  },
  props: ['images'],
  data: () => ({
    swiperOptions: {
      effect: 'fade',
      lazy: true,
      loop: true,
      autoplay: {
        delay: 2000
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        clickable: true,
      },

    }
  }),
  computed: {
    title: function() {
      let title = this.$parent.title

      if(this.$parent.mepandes.length) {
        title += '& Mepandes' 
      }

      return title
    }
  }
};
</script>

<style lang="scss" scoped>
.guest-box {
  display: block;
  border: 1px solid #ccc;
  font-size: 90%;
}

.hero {
  padding-top: 3rem;
}

h1 {
  letter-spacing: 4px;
}

.name {
  .and,
  span {
    @media (max-width: 450px) {
      // display: block;
      // line-height: 1;
      // margin-top: -0.5rem;
      // margin-bottom: -0.5rem;
      // font-size: 80% !important;
      // margin-left: 1rem;
      // margin-right: 1rem;
    }
  }
}

.hero.theme-2 {
  overflow: hidden;
  position: relative;
  margin-top: 0 !important;
  padding-top: 4rem;
}

.hero.theme-2 .corner {
  width: 300px !important;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -20%);
  z-index: 0;
  opacity: .4;
}
</style>