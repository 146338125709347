<template>
  <div class="relative">
    <Youtube
      ref="yt"
      width="100%"
      :height="height"
      :video-id="videoId"
      @playing="playing"
      @ended="ended"
    />

    <transition name="fade">
      <div
        v-if="isEnd"
        class="screen"
        @click="play"
      >
        <div>
          <svg version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M438.3,176.7c0,0-3.6-27.4-14.8-39.4   c-14.2-15.8-30-15.9-37.3-16.8c-52.1-4-130.2-4-130.2-4h-0.2c0,0-78.1,0-130.2,4c-7.3,0.9-23.1,1-37.3,16.8   c-11.1,12.1-14.8,39.4-14.8,39.4S70,208.8,70,240.9V271c0,32.1,3.7,64.2,3.7,64.2s3.6,27.4,14.8,39.4c14.2,15.8,32.7,15.3,41,17   c29.8,3,126.5,4,126.5,4s78.2-0.1,130.3-4.1c7.3-0.9,23.1-1,37.3-16.8c11.1-12,14.8-39.4,14.8-39.4s3.7-32.1,3.7-64.2V241   C442,208.8,438.3,176.7,438.3,176.7z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="7"/><polygon fill="none" points="209.5,186.2 209.5,325.8 325.8,256" stroke="currentColor" stroke-miterlimit="10" stroke-width="7"/></g></svg>
          <div class="text-center">Play</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Youtube } from "vue-youtube";

export default {
  components: {
    Youtube,
  },
  props: {
    videoId: String,
    height: [String, Number],
  },
  data: () => ({
    isEnd: false,
  }),
  methods: {
    playing() {
      console.log('play')
      this.isEnd = false
    },
    ended() {
      console.log('ended')
      this.isEnd = true
    },
    play() {
      this.isEnd = false
      this.$refs.yt.player.playVideo()
    },
  },
}
</script>

<style scoped>
.relative {
  position: relative;
}

.screen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background :#000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.screen svg {
  width: 100%;
  max-width: 100px;
}
</style>
